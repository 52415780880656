import * as React from "react";
import "./PipelineRunStageState.scss";
import FormAppClassName from "../../../../../../../constants/FormAppClassName";
import ControllerState, { stringifyControllerState } from "../../../../../../../nor/pipeline/controllers/types/ControllerState";
import Loader from "../../../../../../../nor/ui/components/loader/Loader";
import Icon from "../../../../../../../nor/ui/components/icon/Icon";
import { CancelledIcon, FailedIcon, FinishedIcon } from "../../../../../../../assets/icon";
import PipelineRunDTO from "../../../../../../../nor/pipeline/dto/PipelineRunDTO";
import StageControllerStateDTO, { parseStageControllerStateDTO } from "../../../../../../../nor/pipeline/controllers/stage/StageControllerStateDTO";
import LogService from "../../../../../../../nor/ts/LogService";
import { map } from "../../../../../../../nor/ts/modules/lodash";
import PipelineControllerState from "../../../state/PipelineControllerState";
import JobControllerStateDTO
    from "../../../../../../../nor/pipeline/controllers/job/JobControllerStateDTO";
import StepControllerStateDTO
    from "../../../../../../../nor/pipeline/controllers/step/types/StepControllerStateDTO";

const LOG = LogService.createLogger('PipelineRunStageState');

export interface PipelineRunStageStateProps {
    readonly className?: string;
    readonly model     ?: PipelineRunDTO;
}

export interface PipelineRunStageStateState {
}

export class PipelineRunStageState extends React.Component<PipelineRunStageStateProps, PipelineRunStageStateState> {

    public static defaultProps: Partial<PipelineRunStageStateProps> = {};

    public constructor (props: PipelineRunStageStateProps) {
        super(props);
    }

    public render () {

        const model = this.props.model;
        const genericStateModel = model?.state;
        const stateModel : StageControllerStateDTO | undefined = parseStageControllerStateDTO(genericStateModel);
        if (stateModel === undefined) {
            LOG.error(`Model was not StageControllerStateDTO: `, genericStateModel);
            return null;
        }

        const agentAccountId : string = model?.agentAccountId ?? 'n/a';
        const runName        : string = stateModel?.name ?? 'n/a';
        const runState       : ControllerState | undefined = stateModel?.state ?? undefined;
        const runStateString : string = runState !== undefined ? stringifyControllerState(runState) : '';

        const isFinished  : boolean = runState === ControllerState.FINISHED;
        const isCancelled : boolean = runState === ControllerState.CANCELLED;
        const isFailed    : boolean = runState === ControllerState.FAILED;
        const isRunning   : boolean = !isFinished && !isCancelled && !isFailed;

        const jobs : JobControllerStateDTO[] = stateModel?.jobs ?? [];

        return (
            <div className={
                FormAppClassName.PIPELINE_RUN_STAGE_STATE + ' ' + (this.props.className ?? '')
                + (runStateString ? ` ${FormAppClassName.PIPELINE_RUN_STAGE_STATE}-run-state-${runStateString}` : '')
                + (runStateString && this.props.className ? ` ${this.props.className}-run-state-${runStateString}` : '')
            }>
                <div className={FormAppClassName.PIPELINE_RUN_STAGE_STATE + '-agent-id'}>{agentAccountId}</div>
                <div className={FormAppClassName.PIPELINE_RUN_STAGE_STATE + '-run-name'}>Stage {runName}</div>
                <div className={
                    `${FormAppClassName.PIPELINE_RUN_STAGE_STATE}-run-state`
                }>{runState !== undefined ? stringifyControllerState(runState) : 'n/a'}</div>
                {isRunning ? (
                    <Loader
                        hiddenTime={0}
                        className={
                            FormAppClassName.PIPELINE_RUN_STAGE_STATE + '-loader'
                        }
                    />
                ) : (
                    isFinished ? (
                        <Icon className={FormAppClassName.PIPELINE_RUN_STAGE_STATE + '-icon'}><FinishedIcon /></Icon>
                    ) : (
                        isFailed ? (
                            <Icon className={FormAppClassName.PIPELINE_RUN_STAGE_STATE + '-icon'}><FailedIcon /></Icon>
                        ) : isCancelled ? (
                            <Icon className={FormAppClassName.PIPELINE_RUN_STAGE_STATE + '-icon'}><CancelledIcon /></Icon>
                        ) : null
                    )
                )
                }

                {map(jobs, (job : JobControllerStateDTO, jobIndex: number) : any => {
                    const jobKey = `job-${jobIndex}-${job?.name}`;
                    const steps = job?.steps ?? [];
                    return (
                        <div key={jobKey}>

                            <PipelineControllerState
                                className={`${FormAppClassName.PIPELINE_RUN_STAGE_STATE}-states-job`}
                                item={job}
                            />

                            {map(steps, (step : StepControllerStateDTO, stepIndex: number) : any => {
                                const stepKey = `${jobKey}-step-${stepIndex}-${step?.name}`;
                                return (
                                    <PipelineControllerState
                                        className={`${FormAppClassName.PIPELINE_RUN_STAGE_STATE}-states-step`}
                                        key={stepKey}
                                        item={step}
                                    />
                                );
                            })}

                        </div>
                    );
                })}

            </div>
        );

    }

}

export default PipelineRunStageState;
