import * as React from "react";
import "./PipelineControllerState.scss";
import FormAppClassName from "../../../../../constants/FormAppClassName";
import ControllerStateDTO from "../../../../../nor/pipeline/controllers/types/ControllerStateDTO";
import ControllerState, { stringifyControllerState } from "../../../../../nor/pipeline/controllers/types/ControllerState";
import ControllerType from "../../../../../nor/pipeline/controllers/types/ControllerType";

export interface PipelineControllerStateProps {
    readonly className ?: string;
    readonly item       : ControllerStateDTO;
}

export interface PipelineControllerStateState {
}

export class PipelineControllerState extends React.Component<PipelineControllerStateProps, PipelineControllerStateState> {

    public static defaultProps: Partial<PipelineControllerStateProps> = {};

    public constructor (props: PipelineControllerStateProps) {
        super(props);
    }

    public render () {

        const name  : string                      = this.props?.item?.name ?? '';
        const state : ControllerState | undefined = this.props?.item?.state;
        const type  : ControllerType  | undefined = this.props?.item?.type;
        const typeLabel : string = this._getTypeLabel(type);

        return (
            <div className={FormAppClassName.PIPELINE_CONTROLLER_STATE + ' ' + (this.props.className ?? '')}>
                <div className={FormAppClassName.PIPELINE_CONTROLLER_STATE+'-name'}>{typeLabel} <strong>{name}</strong></div>
                <div className={FormAppClassName.PIPELINE_CONTROLLER_STATE+'-state'}>{state !== undefined ? stringifyControllerState(state) : 'n/a'}</div>
            </div>
        );

    }

    private _getTypeLabel (value : ControllerType) {
        switch (value) {
            case ControllerType.PIPELINE : return `Pipeline`;
            case ControllerType.JOB      : return `Job`;
            case ControllerType.STAGE    : return `Stage`;
            default                      : return `Step`;
        }
    }

}

export default PipelineControllerState;
