import {FormMessageType} from "./FormMessageType";

export interface FormUnsetSubmitMessageDTO {
    readonly type  : FormMessageType.UNSET_SUBMIT;
}

export function isFormUnsetSubmitMessageDTO (value : any) : value is FormUnsetSubmitMessageDTO {

    return (
        !!value
        && value?.type === FormMessageType.UNSET_SUBMIT
    );

}
