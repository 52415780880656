import {FormMessageType} from "./FormMessageType";

export interface FormUnsetCancelMessageDTO {
    readonly type  : FormMessageType.UNSET_CANCEL;
}

export function isFormUnsetCancelMessageDTO (value : any) : value is FormUnsetCancelMessageDTO {

    return (
        !!value
        && value?.type === FormMessageType.UNSET_CANCEL
    );

}
