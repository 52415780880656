import * as React from "react";
import "./PipelineRunPipelineState.scss";
import FormAppClassName from "../../../../../../../constants/FormAppClassName";
import ControllerState, { stringifyControllerState } from "../../../../../../../nor/pipeline/controllers/types/ControllerState";
import Loader from "../../../../../../../nor/ui/components/loader/Loader";
import Icon from "../../../../../../../nor/ui/components/icon/Icon";
import { CancelledIcon, FailedIcon, FinishedIcon } from "../../../../../../../assets/icon";
import PipelineRunDTO from "../../../../../../../nor/pipeline/dto/PipelineRunDTO";
import PipelineControllerStateDTO, { parsePipelineControllerStateDTO } from "../../../../../../../nor/pipeline/controllers/pipeline/PipelineControllerStateDTO";
import LogService from "../../../../../../../nor/ts/LogService";
import { map } from "../../../../../../../nor/ts/modules/lodash";
import PipelineControllerState from "../../../state/PipelineControllerState";
import StageControllerStateDTO
    from "../../../../../../../nor/pipeline/controllers/stage/StageControllerStateDTO";
import StepControllerStateDTO
    from "../../../../../../../nor/pipeline/controllers/step/types/StepControllerStateDTO";
import JobControllerStateDTO
    from "../../../../../../../nor/pipeline/controllers/job/JobControllerStateDTO";

const LOG = LogService.createLogger('PipelineRunPipelineState');

export interface PipelineRunPipelineStateProps {
    readonly className?: string;
    readonly model     ?: PipelineRunDTO;
}

export interface PipelineRunPipelineStateState {
}

export class PipelineRunPipelineState extends React.Component<PipelineRunPipelineStateProps, PipelineRunPipelineStateState> {

    public static defaultProps: Partial<PipelineRunPipelineStateProps> = {};

    public constructor (props: PipelineRunPipelineStateProps) {
        super(props);
    }

    public render () {

        const model = this.props.model;
        const genericStateModel = model?.state;
        const stateModel : PipelineControllerStateDTO | undefined = parsePipelineControllerStateDTO(genericStateModel);
        if (stateModel === undefined) {
            LOG.error(`Model was not PipelineControllerStateDTO: `, genericStateModel);
            return null;
        }

        const agentAccountId : string = model?.agentAccountId ?? 'n/a';
        const runName        : string = stateModel?.name ?? 'n/a';
        const runState       : ControllerState | undefined = stateModel?.state ?? undefined;
        const runStateString : string = runState !== undefined ? stringifyControllerState(runState) : '';

        const isFinished  : boolean = runState === ControllerState.FINISHED;
        const isCancelled : boolean = runState === ControllerState.CANCELLED;
        const isFailed    : boolean = runState === ControllerState.FAILED;
        const isRunning   : boolean = !isFinished && !isCancelled && !isFailed;

        const stages : StageControllerStateDTO[] = stateModel?.stages ?? [];

        return (
            <div className={
                FormAppClassName.PIPELINE_RUN_PIPELINE_STATE + ' ' + (this.props.className ?? '')
                + (runStateString ? ` ${FormAppClassName.PIPELINE_RUN_PIPELINE_STATE}-run-state-${runStateString}` : '')
                + (runStateString && this.props.className ? ` ${this.props.className}-run-state-${runStateString}` : '')
            }>
                <div className={FormAppClassName.PIPELINE_RUN_PIPELINE_STATE + '-agent-id'}>{agentAccountId}</div>
                <div className={FormAppClassName.PIPELINE_RUN_PIPELINE_STATE + '-run-name'}>Pipeline {runName}</div>
                <div className={
                    `${FormAppClassName.PIPELINE_RUN_PIPELINE_STATE}-run-state`
                }>{runState !== undefined ? stringifyControllerState(runState) : 'n/a'}</div>
                {isRunning ? (
                    <Loader
                        hiddenTime={0}
                        className={
                            FormAppClassName.PIPELINE_RUN_PIPELINE_STATE + '-loader'
                        }
                    />
                ) : (
                    isFinished ? (
                        <Icon className={FormAppClassName.PIPELINE_RUN_PIPELINE_STATE + '-icon'}><FinishedIcon /></Icon>
                    ) : (
                        isFailed ? (
                            <Icon className={FormAppClassName.PIPELINE_RUN_PIPELINE_STATE + '-icon'}><FailedIcon /></Icon>
                        ) : isCancelled ? (
                            <Icon className={FormAppClassName.PIPELINE_RUN_PIPELINE_STATE + '-icon'}><CancelledIcon /></Icon>
                        ) : null
                    )
                )
                }

                {map(stages, (stage : StageControllerStateDTO, stageIndex: number) : any => {
                    const stageKey = `pipeline-stage-${stageIndex}-${stage?.name}`;
                    const jobs : JobControllerStateDTO[] = stage?.jobs ?? [];
                    return (
                        <div key={stageKey}>

                            <PipelineControllerState
                                className={`${FormAppClassName.PIPELINE_RUN_PIPELINE_STATE}-states-stage`}
                                item={stage}
                            />

                            {map(jobs, (job : JobControllerStateDTO, jobIndex: number) : any => {
                                const jobKey = `${stageKey}-job-${jobIndex}-${job?.name}`;
                                const steps = job?.steps ?? [];
                                return (
                                    <div key={jobKey}>

                                        <PipelineControllerState
                                            className={`${FormAppClassName.PIPELINE_RUN_PIPELINE_STATE}-states-job`}
                                            item={job}
                                        />

                                        {map(steps, (step : StepControllerStateDTO, stepIndex: number) : any => {
                                            const stepKey = `${jobKey}-step-${stepIndex}-${step?.name}`;
                                            return (
                                                <PipelineControllerState
                                                    className={`${FormAppClassName.PIPELINE_RUN_PIPELINE_STATE}-states-step`}
                                                    key={stepKey}
                                                    item={step}
                                                />
                                            );
                                        })}

                                    </div>
                                );
                            })}

                        </div>
                    );
                })}

            </div>
        );

    }

}

export default PipelineRunPipelineState;
