import * as React from "react";
import "./PipelineRunMonitor.scss";
import FormAppClassName from "../../../../../constants/FormAppClassName";
import PipelineService from "../../../../../services/PipelineService";
import RepositoryEntry from "../../../../../nor/ts/simpleRepository/types/RepositoryEntry";
import PipelineRunDTO from "../../../../../nor/pipeline/dto/PipelineRunDTO";
import LogService from "../../../../../nor/ts/LogService";
import Loader from "../../../../../nor/ui/components/loader/Loader";
import ControllerState from "../../../../../nor/pipeline/controllers/types/ControllerState";
import ControllerType from "../../../../../nor/pipeline/controllers/types/ControllerType";
import { VoidCallback } from "../../../../../nor/ts/interfaces/callbacks";
import PipelineRunStepState from "./states/step/PipelineRunStepState";
import PipelineRunStageState from "./states/stage/PipelineRunStageState";
import PipelineRunPipelineState from "./states/pipeline/PipelineRunPipelineState";
import PipelineRunJobState from "./states/job/PipelineRunJobState";

const LOG = LogService.createLogger('PipelineRunMonitor');

// const ERROR_RETRY_TIMEOUT = 2500;

export enum MonitorState {
    CONSTRUCTED,
    LOAD_SUCCESS,
    LOAD_ERROR
}

export interface PipelineRunMonitorProps {
    readonly className  ?: string;
    readonly pipelineId  : string;
    readonly runId       : string;
}

export interface PipelineRunMonitorState {

    readonly state   : MonitorState;
    readonly payload : RepositoryEntry<PipelineRunDTO> | undefined;

}

export class PipelineRunMonitor extends React.Component<PipelineRunMonitorProps, PipelineRunMonitorState> {

    public static defaultProps: Partial<PipelineRunMonitorProps> = {};

    private readonly _errorRetryCallback : VoidCallback;
    private _errorRetryTimer : any | undefined;
    private _destroyed : boolean;

    public constructor (props: PipelineRunMonitorProps) {
        super(props);
        this.state = {
            state: MonitorState.CONSTRUCTED,
            payload: undefined
        };
        this._destroyed = false;
        this._errorRetryTimer = undefined;
        this._errorRetryCallback = this._onErrorRetry.bind(this);
    }

    public componentDidMount (): void {

        this._loadFromBackend().catch(err => {
            LOG.error(`Load error: `, err);
        });

    }

    public componentWillUnmount (): void {

        this._destroyed = true;

        if (this._errorRetryTimer) {
            clearTimeout(this._errorRetryTimer);
            this._errorRetryTimer = undefined;
        }

    }

    public render () {

        const monitorState : MonitorState = this.state.state;

        if (monitorState === MonitorState.CONSTRUCTED) {
            return (
                <div className={FormAppClassName.PIPELINE_RUN_MONITOR + ' ' + (this.props.className ?? '')}>
                    <Loader />
                </div>
            );
        }

        if (monitorState === MonitorState.LOAD_ERROR) {
            return (
                <div className={FormAppClassName.PIPELINE_RUN_MONITOR + ' ' + (this.props.className ?? '')}>
                    Load error
                </div>
            );
        }

        const model   : PipelineRunDTO | undefined = this.state?.payload?.data;
        const runType : ControllerType | undefined = model?.state?.type ?? undefined;

        switch (runType) {

            case ControllerType.PIPELINE :
                return (
                    <PipelineRunPipelineState
                        className={FormAppClassName.PIPELINE_RUN_MONITOR+ ' ' + (this.props.className ?? '')}
                        model={model}
                    />
                );

            case ControllerType.JOB      :
                return (
                    <PipelineRunJobState
                        className={FormAppClassName.PIPELINE_RUN_MONITOR+ ' ' + (this.props.className ?? '')}
                        model={model}
                    />
                );

            case ControllerType.STAGE    :
                return (
                    <PipelineRunStageState
                        className={FormAppClassName.PIPELINE_RUN_MONITOR+ ' ' + (this.props.className ?? '')}
                        model={model}
                    />
                );

            case ControllerType.NONE     :
            case ControllerType.TASK     :
            case ControllerType.STEP     :
            case ControllerType.FILE     :
            case ControllerType.SCRIPT   :
            case ControllerType.VARIABLE :
            case ControllerType.JSON     :
            case ControllerType.CSV      :
            case ControllerType.GIT      :
            default:
                return (
                    <PipelineRunStepState
                        className={FormAppClassName.PIPELINE_RUN_MONITOR+ ' ' + (this.props.className ?? '')}
                        model={model}
                    />
                );

        }

    }

    private async _loadFromBackend (
        pollVersion: number | undefined = undefined
    ) : Promise<void> {

        try {

            const payload : RepositoryEntry<PipelineRunDTO> = await PipelineService.getPipelineRun(
                this.props.pipelineId,
                this.props.runId,
                pollVersion
            );

            this.setState({
                payload: payload,
                state: MonitorState.LOAD_SUCCESS
            });

            const runState    : ControllerState | undefined = payload?.data?.state?.state ?? undefined;
            const isFinished  : boolean = runState === ControllerState.FINISHED;
            const isCancelled : boolean = runState === ControllerState.CANCELLED;
            const isFailed    : boolean = runState === ControllerState.FAILED;
            const isRunning   : boolean = !isFinished && !isCancelled && !isFailed;

            if ( isRunning && payload?.version >= 0 && !this._destroyed ) {
                this._loadFromBackend(payload?.version).catch( (err) => {
                    LOG.error(`Load error: `, err);
                });
            }

        } catch (err) {

            LOG.error(`Load error: `, err);

            this.setState({state: MonitorState.LOAD_ERROR})

            // if (this._errorRetryTimer) {
            //     clearTimeout(this._errorRetryTimer);
            //     this._errorRetryTimer = undefined;
            // }
            // LOG.debug(`Scheduling retry after ${ERROR_RETRY_TIMEOUT} ms`);
            // this._errorRetryTimer = setTimeout(this._errorRetryCallback, ERROR_RETRY_TIMEOUT);

        }

    }

    private _onErrorRetry () {

        if (this._errorRetryTimer) {
            this._errorRetryTimer = undefined;
        }

        LOG.debug(`_onErrorRetry: Trying again loading`);
        this._loadFromBackend().catch(err => {
            LOG.error(`Load error: `, err);
        });

    }

}

export default PipelineRunMonitor;
