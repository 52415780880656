import {FormMessageType} from "./FormMessageType";

export interface FormSetCancelMessageDTO {
    readonly type  : FormMessageType.SET_CANCEL;
}

export function isFormSetCancelMessageDTO (value : any) : value is FormSetCancelMessageDTO {

    return (
        !!value
        && value?.type === FormMessageType.SET_CANCEL
    );

}
