import {FormMessageType} from "./FormMessageType";
import FormModel, {isFormModel} from "../../nor/ui/types/FormModel";

export interface FormSetModelMessageDTO {
    readonly type: FormMessageType.SET_MODEL;
    readonly model: FormModel;
}

export function isFormSetModelMessageDTO (value : any) : value is FormSetModelMessageDTO {

    return (
        !!value
        && value?.type === FormMessageType.SET_MODEL
        && isFormModel(value?.model)
    );

}
