
/** These are from nor.uiFrame library */
export enum FormMessageType {

    SET_MODEL = "nor.uiFrame.setModel",
    SET_VALUE = "nor.uiFrame.setValue",
    SET_SUBMIT    = "nor.uiFrame.setSubmit",
    SET_CANCEL    = "nor.uiFrame.setCancel",
    UNSET_SUBMIT  = "nor.uiFrame.unsetSubmit",
    UNSET_CANCEL  = "nor.uiFrame.unsetCancel",

    // Receiving message types
    SUBMIT        = "nor.uiFrame.submit",
    CANCEL        = "nor.uiFrame.cancel"

}

export function isFormMessageType (value : any) : value is FormMessageType {
    switch(value) {
        case FormMessageType.SET_MODEL:
        case FormMessageType.SET_VALUE:
        case FormMessageType.SET_SUBMIT:
        case FormMessageType.SET_CANCEL:
        case FormMessageType.UNSET_SUBMIT:
        case FormMessageType.UNSET_CANCEL:
        case FormMessageType.SUBMIT:
        case FormMessageType.CANCEL:
            return true;

        default:
            return false;

    }
}

export function parseFormMessageType (value : any) : FormMessageType | undefined {
    switch(value) {
        case FormMessageType.SET_MODEL: return FormMessageType.SET_MODEL;
        case FormMessageType.SET_VALUE: return FormMessageType.SET_VALUE;
        case FormMessageType.SET_SUBMIT: return FormMessageType.SET_SUBMIT;
        case FormMessageType.SET_CANCEL: return FormMessageType.SET_CANCEL;
        case FormMessageType.UNSET_SUBMIT: return FormMessageType.UNSET_SUBMIT;
        case FormMessageType.UNSET_CANCEL: return FormMessageType.UNSET_CANCEL;
        case FormMessageType.SUBMIT: return FormMessageType.SUBMIT;
        case FormMessageType.CANCEL: return FormMessageType.CANCEL;
        default: return undefined;
    }
}

// eslint-disable-next-line
export namespace FormMessageType {

    export function test (value: any) : value is FormMessageType {
        return isFormMessageType(value);
    }

    export function parse (value: any) : FormMessageType | undefined {
        return parseFormMessageType(value);
    }

}
