export enum FormWindowEventServiceEvent {

    UNSET_SUBMIT = "FormWindowEventService:unsetSubmit",
    UNSET_CANCEL = "FormWindowEventService:unsetCancel",
    SET_CANCEL   = "FormWindowEventService:setCancel",
    SET_SUBMIT   = "FormWindowEventService:setSubmit",
    SET_MODEL    = "FormWindowEventService:setModel",
    SET_VALUE    = "FormWindowEventService:setValue"

}

// eslint-disable-next-line
export namespace FormWindowEventServiceEvent {

    export function test (value: any): value is FormWindowEventServiceEvent {
        switch (value) {

            case FormWindowEventServiceEvent.UNSET_SUBMIT:
            case FormWindowEventServiceEvent.UNSET_CANCEL:
            case FormWindowEventServiceEvent.SET_CANCEL:
            case FormWindowEventServiceEvent.SET_SUBMIT:
            case FormWindowEventServiceEvent.SET_MODEL:
            case FormWindowEventServiceEvent.SET_VALUE:
                return true;

            default:
                return false;

        }
    }

}
