import * as React from "react";
import "./PipelineRunJobState.scss";
import FormAppClassName from "../../../../../../../constants/FormAppClassName";
import ControllerState, { stringifyControllerState } from "../../../../../../../nor/pipeline/controllers/types/ControllerState";
import Loader from "../../../../../../../nor/ui/components/loader/Loader";
import Icon from "../../../../../../../nor/ui/components/icon/Icon";
import { CancelledIcon, FailedIcon, FinishedIcon } from "../../../../../../../assets/icon";
import PipelineRunDTO from "../../../../../../../nor/pipeline/dto/PipelineRunDTO";
import JobControllerStateDTO, {
    parseJobControllerStateDTO
} from "../../../../../../../nor/pipeline/controllers/job/JobControllerStateDTO";
import LogService from "../../../../../../../nor/ts/LogService";
import StepControllerStateDTO
    from "../../../../../../../nor/pipeline/controllers/step/types/StepControllerStateDTO";
import { map } from "../../../../../../../nor/ts/modules/lodash";
import PipelineControllerState from "../../../state/PipelineControllerState";

const LOG = LogService.createLogger('PipelineRunJobState');

export interface PipelineRunJobStateProps {
    readonly className ?: string;
    readonly model     ?: PipelineRunDTO;
}

export interface PipelineRunJobStateState {
}

export class PipelineRunJobState extends React.Component<PipelineRunJobStateProps, PipelineRunJobStateState> {

    public static defaultProps: Partial<PipelineRunJobStateProps> = {};

    public constructor (props: PipelineRunJobStateProps) {
        super(props);
    }

    public render () {

        const model = this.props.model;
        const genericStateModel = model?.state;
        const stateModel : JobControllerStateDTO | undefined = parseJobControllerStateDTO(genericStateModel);
        if (stateModel === undefined) {
            LOG.error(`Model was not JobControllerStateDTO: `, genericStateModel);
            return null;
        }

        const agentAccountId : string = model?.agentAccountId ?? 'n/a';
        const runName        : string = stateModel?.name ?? 'n/a';
        const runState       : ControllerState | undefined = stateModel?.state ?? undefined;
        const runStateString : string = runState !== undefined ? stringifyControllerState(runState) : '';

        const steps : StepControllerStateDTO[] = stateModel?.steps ?? [];

        const isFinished  : boolean = runState === ControllerState.FINISHED;
        const isCancelled : boolean = runState === ControllerState.CANCELLED;
        const isFailed    : boolean = runState === ControllerState.FAILED;
        const isRunning   : boolean = !isFinished && !isCancelled && !isFailed;

        return (
            <div className={
                FormAppClassName.PIPELINE_RUN_JOB_STATE + ' ' + (this.props.className ?? '')
                + (runStateString ? ` ${FormAppClassName.PIPELINE_RUN_JOB_STATE}-run-state-${runStateString}` : '')
                + (runStateString && this.props.className ? ` ${this.props.className}-run-state-${runStateString}` : '')
            }>
                <div className={FormAppClassName.PIPELINE_RUN_JOB_STATE + '-agent-id'}>{agentAccountId}</div>
                <div className={FormAppClassName.PIPELINE_RUN_JOB_STATE + '-run-name'}>Job {runName}</div>
                <div className={
                    `${FormAppClassName.PIPELINE_RUN_JOB_STATE}-run-state`
                }>{runState !== undefined ? stringifyControllerState(runState) : 'n/a'}</div>
                {isRunning ? (
                    <Loader
                        hiddenTime={0}
                        className={
                            FormAppClassName.PIPELINE_RUN_JOB_STATE + '-loader'
                        }
                    />
                ) : (
                    isFinished ? (
                        <Icon className={FormAppClassName.PIPELINE_RUN_JOB_STATE + '-icon'}><FinishedIcon /></Icon>
                    ) : (
                        isFailed ? (
                            <Icon className={FormAppClassName.PIPELINE_RUN_JOB_STATE + '-icon'}><FailedIcon /></Icon>
                        ) : isCancelled ? (
                            <Icon className={FormAppClassName.PIPELINE_RUN_JOB_STATE + '-icon'}><CancelledIcon /></Icon>
                        ) : null
                    )
                )
                }

                {map(steps, (step : StepControllerStateDTO, stepIndex: number) : any => {
                    return (
                        <PipelineControllerState
                            className={`${FormAppClassName.PIPELINE_RUN_JOB_STATE}-states-step`}
                            key={`step-${stepIndex}-${step?.name}`}
                            item={step}
                        />
                    );
                })}

            </div>
        );

    }

}

export default PipelineRunJobState;
