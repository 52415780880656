import * as React from "react";
import "./PipelineRunStepState.scss";
import FormAppClassName from "../../../../../../../constants/FormAppClassName";
import ControllerState, { stringifyControllerState } from "../../../../../../../nor/pipeline/controllers/types/ControllerState";
import Loader from "../../../../../../../nor/ui/components/loader/Loader";
import Icon from "../../../../../../../nor/ui/components/icon/Icon";
import { CancelledIcon, FailedIcon, FinishedIcon } from "../../../../../../../assets/icon";
import PipelineRunDTO from "../../../../../../../nor/pipeline/dto/PipelineRunDTO";
import LogService from "../../../../../../../nor/ts/LogService";
import StepControllerStateDTO
    , { isStepControllerStateDTO } from "../../../../../../../nor/pipeline/controllers/step/types/StepControllerStateDTO";
import ControllerStateDTO
    from "../../../../../../../nor/pipeline/controllers/types/ControllerStateDTO";

const LOG = LogService.createLogger('PipelineRunStepState');

export interface PipelineRunStepStateProps {
    readonly className ?: string;
    readonly model     ?: PipelineRunDTO;
}

export interface PipelineRunStepStateState {
}

export class PipelineRunStepState extends React.Component<PipelineRunStepStateProps, PipelineRunStepStateState> {

    public static defaultProps: Partial<PipelineRunStepStateProps> = {};

    public constructor (props: PipelineRunStepStateProps) {
        super(props);
    }

    public render () {

        const model             : PipelineRunDTO         | undefined = this.props.model;
        const genericStateModel : ControllerStateDTO     | undefined = model?.state;
        const stateModel        : StepControllerStateDTO | undefined = isStepControllerStateDTO(genericStateModel) ? genericStateModel : undefined;

        if (stateModel === undefined) {
            LOG.error(`Model was not StepControllerStateDTO: `, genericStateModel);
            return null;
        }

        const agentAccountId : string = model?.agentAccountId ?? 'n/a';
        const runName        : string = stateModel?.name    ?? 'n/a';
        const runState       : ControllerState | undefined = stateModel?.state ?? undefined;
        const runStateString : string = runState !== undefined ? stringifyControllerState(runState) : '';

        const isFinished  : boolean = runState === ControllerState.FINISHED;
        const isCancelled : boolean = runState === ControllerState.CANCELLED;
        const isFailed    : boolean = runState === ControllerState.FAILED;
        const isRunning   : boolean = !isFinished && !isCancelled && !isFailed;

        return (
            <div className={
                FormAppClassName.PIPELINE_RUN_STEP_STATE + ' ' + (this.props.className ?? '')
                + (runStateString ? ` ${FormAppClassName.PIPELINE_RUN_STEP_STATE}-run-state-${runStateString}` : '')
                + (runStateString && this.props.className ? ` ${this.props.className}-run-state-${runStateString}` : '')
            }>
                <div className={FormAppClassName.PIPELINE_RUN_STEP_STATE + '-agent-id'}>{agentAccountId}</div>
                <div className={FormAppClassName.PIPELINE_RUN_STEP_STATE + '-run-name'}>Step {runName}</div>
                <div className={
                    `${FormAppClassName.PIPELINE_RUN_STEP_STATE}-run-state`
                }>{runState !== undefined ? stringifyControllerState(runState) : 'n/a'}</div>
                {isRunning ? (
                    <Loader
                        hiddenTime={0}
                        className={
                            FormAppClassName.PIPELINE_RUN_STEP_STATE + '-loader'
                        }
                    />
                ) : (
                    isFinished ? (
                        <Icon className={FormAppClassName.PIPELINE_RUN_STEP_STATE + '-icon'}><FinishedIcon /></Icon>
                    ) : (
                        isFailed ? (
                            <Icon className={FormAppClassName.PIPELINE_RUN_STEP_STATE + '-icon'}><FailedIcon /></Icon>
                        ) : isCancelled ? (
                            <Icon className={FormAppClassName.PIPELINE_RUN_STEP_STATE + '-icon'}><CancelledIcon /></Icon>
                        ) : null
                    )
                )
                }
            </div>
        );

    }

}

export default PipelineRunStepState;
